import React from 'react'
import "../allcss/allcss.css"
import Header from '../../../Component/Header/Header'
import Footer from '../../../Component/Footer/Footer'
const Automation = () => {
  return (
    <>
    <Header />
     <div className="container background_img_auto"></div>
     <Footer />
      
    </>
  )
}

export default Automation
