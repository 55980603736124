import React from 'react'
import "../allcss/allcss.css"
import Header from '../../../Component/Header/Header'
import Footer from '../../../Component/Footer/Footer'
const Manual = () => {
  return (
    <>
    <Header />
       <div className="container background_img_manual"></div>
       <Footer />
    </>
  )
}

export default Manual
